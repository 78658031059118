import React, { useState } from 'react';
import axios from 'axios';
import '../assets/Copilotpro.css';
import Header from '../assets/Header.js';

const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

function CopilotPro() {
  // Schrittsteuerung: 1 = Modulidentifikation, 2 = Parameterbelegung & Dialog
  const [step, setStep] = useState(1);
  const [chatInput, setChatInput] = useState('');
  const [chatLog, setChatLog] = useState([]);
  // Modulvorschläge von ChatGPT (["B1", "B3"])
  const [moduleSuggestions, setModuleSuggestions] = useState([]);
  // Ausgewählte Module, die der Anwender bestätigen möchte
  const [selectedModules, setSelectedModules] = useState([]);
  // Parameter für jedes Modul ({ B1: { diameter: '', pressure: '', temperature: '' } })
  const [moduleParameters, setModuleParameters] = useState({});

  // Nachricht im Schritt 1 an ChatGPT senden, um Modulvorschläge zu erhalten
  const sendChatMessage = () => {
    if (!chatInput.trim()) return;

    const userEntry = { sender: 'user', message: chatInput };
    setChatLog(prev => [...prev, userEntry]);

    axios
      .post(`${apiBaseUrl}/api/copilotpro/chat`, { message: chatInput, user_id: 'test_user' })
      .then(response => {
        // Erwartet: { result: "Antworttext...", module_suggestions: ["B1", "B3"] }
        const { result, module_suggestions } = response.data;
        const assistantEntry = { sender: 'assistant', message: result };
        setChatLog(prev => [...prev, assistantEntry]);

        if (module_suggestions) {
          setModuleSuggestions(module_suggestions);
          // Vorauswahl: alle vorgeschlagenen Module werden aktiv ausgewählt
          setSelectedModules(module_suggestions);
        }
        setChatInput('');
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  // Umschalten der Modulauswahl (Checkbox)
  const toggleModuleSelection = (module) => {
    if (selectedModules.includes(module)) {
      setSelectedModules(selectedModules.filter(m => m !== module));
    } else {
      setSelectedModules([...selectedModules, module]);
    }
  };

  // Wechsel zu Schritt 2: Für die ausgewählten Module Parameterformulare anzeigen
  const proceedToStep2 = () => {
    // Für jedes ausgewählte Modul werden leere Standardwerte gesetzt, falls noch nicht vorhanden
    const defaults = {};
    selectedModules.forEach(module => {
      if (!moduleParameters[module]) {
        defaults[module] = {
          diameter: '',
          pressure: '',
          temperature: ''
        };
      }
    });
    setModuleParameters(prev => ({ ...defaults, ...prev }));
    setStep(2);
  };

  // Handler für Änderungen in den Parameterfeldern
  const handleParameterChange = (moduleName, paramName, value) => {
    setModuleParameters(prev => ({
      ...prev,
      [moduleName]: {
        ...prev[moduleName],
        [paramName]: value
      }
    }));
  };

  // Nachricht im Schritt 2 an ChatGPT senden (inkl. aktueller Modulparameter)
  const sendParameterChat = () => {
    const paramDetails = JSON.stringify(moduleParameters);
    const fullMessage = chatInput + "\n\nAktuelle Modulparameter: " + paramDetails;
    const userEntry = { sender: 'user', message: fullMessage };
    setChatLog(prev => [...prev, userEntry]);

    axios
      .post(`${apiBaseUrl}/api/copilotpro/chat`, { message: fullMessage, user_id: 'test_user' })
      .then(response => {
        const { result } = response.data;
        const assistantEntry = { sender: 'assistant', message: result };
        setChatLog(prev => [...prev, assistantEntry]);
        setChatInput('');
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <div className="copilotpro-container">
      <Header title="CopilotPro - Engineering Assistant" />
      <h1>CopilotPro - Engineering Assistant</h1>
      
      {step === 1 && (
        <div className="step1-container">
          <h2>Schritt 1: Modulidentifikation</h2>
          <div className="chat-window">
            {chatLog.map((entry, index) => (
              <div key={index} className={`chat-entry ${entry.sender}`}>
                <div className="message">{entry.message}</div>
              </div>
            ))}
          </div>
          <div className="chat-input-container">
            <input
              type="text"
              placeholder="Ihre Frage..."
              value={chatInput}
              onChange={e => setChatInput(e.target.value)}
              onKeyPress={e => { if (e.key === 'Enter') sendChatMessage() }}
            />
            <button onClick={sendChatMessage}>Senden</button>
          </div>
          {moduleSuggestions.length > 0 && (
            <div className="module-suggestions">
              <h3>Vorgeschlagene Module</h3>
              <ul>
                {moduleSuggestions.map((module, index) => (
                  <li key={index}>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedModules.includes(module)}
                        onChange={() => toggleModuleSelection(module)}
                      />
                      {module}
                    </label>
                  </li>
                ))}
              </ul>
              <button onClick={proceedToStep2}>Weiter zu Schritt 2</button>
            </div>
          )}
        </div>
      )}

      {step === 2 && (
        <div className="step2-container">
          <h2>Schritt 2: Parameter eingeben & Copilot-Dialog</h2>
          <div className="module-parameters">
            {selectedModules.map((module, index) => (
              <div key={index} className="module-parameter-form">
                <h3>Modul: {module}</h3>
                <div className="form-group">
                  <label>Durchmesser (mm):</label>
                  <input
                    type="number"
                    value={moduleParameters[module]?.diameter || ''}
                    onChange={e => handleParameterChange(module, 'diameter', e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Druck (bar):</label>
                  <input
                    type="number"
                    value={moduleParameters[module]?.pressure || ''}
                    onChange={e => handleParameterChange(module, 'pressure', e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Temperatur (°C):</label>
                  <input
                    type="number"
                    value={moduleParameters[module]?.temperature || ''}
                    onChange={e => handleParameterChange(module, 'temperature', e.target.value)}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="chat-window">
            {chatLog.map((entry, index) => (
              <div key={index} className={`chat-entry ${entry.sender}`}>
                <div className="message">{entry.message}</div>
              </div>
            ))}
          </div>
          <div className="chat-input-container">
            <input
              type="text"
              placeholder="Weitere Fragen oder Anpassungen..."
              value={chatInput}
              onChange={e => setChatInput(e.target.value)}
              onKeyPress={e => { if (e.key === 'Enter') sendParameterChat() }}
            />
            <button onClick={sendParameterChat}>Senden</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CopilotPro;

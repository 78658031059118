import React, { useState } from 'react';
import axios from 'axios';
import '../assets/Copilot.css';
import Header from '../assets/Header.js';

// Verwende die Umgebungsvariable für die API-URL, sonst Fallback zu localhost
const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

function CopilotModule() {
  const [chatInput, setChatInput] = useState('');
  const [chatLog, setChatLog] = useState([]);
  const [calcResult, setCalcResult] = useState(null);
  const [logs, setLogs] = useState([]);
  const [showLogs, setShowLogs] = useState(false); // State zum Ein-/Ausklappen der Logs

  const addLog = (message) => {
    setLogs((prevLogs) => [...prevLogs, message]);
  };

  const handleSendMessage = () => {
    if (!chatInput) return;

    // Benutzer-Nachricht zur Chat-Historie hinzufügen
    const userEntry = { sender: 'user', message: chatInput };
    setChatLog((prev) => [...prev, userEntry]);
    addLog(`User: ${chatInput}`);

    // Sende die Chat-Nachricht an den Flask-Endpunkt
    axios
      .post(`${apiBaseUrl}/api/copilot/chat`, { message: chatInput })
      .then((response) => {
        const { result, calc_result } = response.data;
        // Antwort von Copilot zur Chat-Historie hinzufügen
        const copilotEntry = { sender: 'copilot', message: result };
        setChatLog((prev) => [...prev, copilotEntry]);
        addLog(`Copilot: ${result}`);
        if (calc_result) {
          setCalcResult(calc_result);
        }
        setChatInput('');
      })
      .catch((error) => {
        console.error('Error sending message:', error);
        addLog(`Error sending message: ${error.message}`);
      });
  };

  return (
    <div className="container">
      <Header title="Copilot - Engineering Assistant" />

      <div className="chat-container">
        <div className="chat-log">
          {chatLog.map((entry, index) => (
            <div
              key={index}
              className={`chat-entry ${
                entry.sender === 'user' ? 'user-bubble' : 'copilot-bubble'
              }`}
            >
              <div className="bubble-header">
                {entry.sender === 'user' ? 'You' : 'Copilot'}
              </div>
              <div className="bubble-content">{entry.message}</div>
            </div>
          ))}
        </div>
        <div className="chat-input-container">
          <input
            type="text"
            className="chat-input"
            placeholder="Enter your message..."
            value={chatInput}
            onChange={(e) => setChatInput(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSendMessage();
              }
            }}
          />
          <button onClick={handleSendMessage} className="btn-send">
            Send
          </button>
        </div>
      </div>

      {calcResult && (
        <div className="calc-result">
          <h3>Calculation Result:</h3>
          <ul>
            <li>
              <strong>Safety Factor:</strong> {calcResult.safety_factor}
            </li>
            <li>
              <strong>Max Stress (MPa):</strong> {calcResult.max_stress_MPa}
            </li>
            <li>
              <strong>Deflection (m):</strong> {calcResult.deflection_m}
            </li>
            <li>{calcResult.result_summary}</li>
          </ul>
        </div>
      )}

      {/* Toggle-Button zum Ein-/Ausklappen der Logs */}
      <button
        className="toggle-log-button"
        onClick={() => setShowLogs((prev) => !prev)}
      >
        {showLogs ? 'Hide Logs' : 'Show Logs'}
      </button>

      {showLogs && (
        <div className="log-section">
          <h3>Logs:</h3>
          <ul>
            {logs.map((log, index) => (
              <li key={index}>{log}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default CopilotModule;
